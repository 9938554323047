import * as yup from 'yup';
import { transformAsCurrency } from '~/utils/functions';

export const schema = yup
  .object({
    des_nome: yup.string().trim().required(),
    des_nome_espaco: yup.string().required(),
    des_bd_endpoint: yup.string().required(),
    des_bd_porta: yup.string().required(),
    des_bd_nome: yup.string().required(),
    des_bd_usuario: yup.string().required(),
    des_bd_senha: yup.string().when('isUpdate', {
      is: true,
      then: yup.string().notRequired(),
      otherwise: yup.string().required(),
    }),
    cod_grupo: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();
