import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .url-content {
    display: grid;
    grid-template-columns: 49% 47%;
    width: 100%;
    gap: 1.25rem;
  }
`;

export const ButtonReturnCards = styled(motion.button)`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 100%;
  background-color: #ffc107;
  color: #fff;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;

  animation: all 0.25s ease-in;
  &.addNew {
    background-color: #007bff;
    &.span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &:hover {
    opacity: 0.7;
  }
`;

export const ContainerLoading = styled.div`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
`;
